/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Header from './header';
import Footer from './footer';
import SiteIndex from './home/site-index';

const Layout = ({ wrapperClass, hideHeaderFooter, showSiteIndex, showStickyButton, children }) => {
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
    }
  }, []);

  return (
    <div className={wrapperClass}>
      {hideHeaderFooter && (
        <Header />
      )}
      <main>{children}</main>
      {showSiteIndex && (
        <SiteIndex />
      )}
      {hideHeaderFooter && (
        <Footer />
      )}
      {showStickyButton && (
        <Link to="/quote" className={`sticky-btn ${offset > 10 ? 'active' : ''}`}>Get free quotes</Link>
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
