/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ title, description, canonical, ogTitle, ogDescription, ogType, ogImage, ogUrl, stucturedData }) {
  const data = useStaticQuery(
    graphql`
      query {
        seo {
          description
          title
          canonical
          og {
            description
            image
            title
            type
            url
          }
        }
      }
    `
  );
  const lang = 'en;'
  const {seo} = data;
  const pageTitle = (title !== '') ? title : seo.title;
  const pageDescription = (description !== '') ? description : seo.description;
  const pageCanonical = (canonical !== '') ? canonical : seo.canonical;
  const ogPageTitle = (ogTitle !== '') ? ogTitle : seo.og.title;
  const ogPageDescription = (ogDescription !== '') ? ogDescription : seo.og.description;
  const ogPageImage = (ogImage !== '') ? ogImage : seo.og.image;
  const ogPageType = (ogType !== '') ? ogType : seo.og.type;
  const ogPageUrl = (ogUrl !== '') ? ogUrl : seo.og.url;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      titleTemplate={pageTitle}
      meta={[
        {
          name: `description`,
          content: pageDescription,
        },
        {
          property: `og:title`,
          content: ogPageTitle,
        },
        {
          property: `og:description`,
          content: ogPageDescription,
        },
        {
          property: `og:type`,
          content: ogPageType,
        },
        {
          property: `og:url`,
          content: ogPageUrl,
        },
        {
          property: `og:image`,
          content: ogPageImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: ogPageTitle,
        },
        {
          name: `twitter:description`,
          content: ogPageDescription,
        },
      ]}
    >
      {pageCanonical && (
        <link rel="canonical" href={pageCanonical} />
      )}
      {stucturedData && (
        <script type="application/ld+json">
          {stucturedData}
        </script>
      )}
    </Helmet>
  )
}

export default SEO;
