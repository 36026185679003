import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { organiseLocations } from '../helper';
import '../../assets/styles/components/site-index.scss';

const SiteIndex = () => {
  const data = useStaticQuery(graphql`
    query {
      allServices {
        edges {
          node {
            url
            text
            id
          }
        }
      }
      allCarmakes {
        edges {
          node {
            url
            text
            id
          }
        }
      }
      allSuburbList {
        edges {
          node {
            url
            text
            state
            slug
          }
        }
      }
      allStateList {
        edges {
          node {
            id
            url
            text
          }
        }
      }
      general {
        country_code
      }
    }
  `);
  const services = data.allServices.edges;
  const carMakes = data.allCarmakes.edges;
  const { allSuburbList, allStateList } = data;
  const [states, setStates] = useState([]);
  const [showAccordion, setShowAccordion] = useState([]);

  useEffect(() => {
    setStates(organiseLocations(allSuburbList.edges, allStateList.edges));
  }, [allStateList]);

  const accordion = [];
  const height = [];

  const toggleAccordion = (idx, e) => {
    const parent = e.currentTarget.parentNode;
    let accordionBody = parent.getElementsByClassName('accordion-body-inner')[0].offsetHeight;
    let toggle = true;
    if (typeof showAccordion.accordion !== 'undefined') {
      toggle = !showAccordion.accordion[idx];
    }

    accordion[idx] = toggle;
    height[idx] = accordionBody;

    setShowAccordion({
      accordion,
      height
    });
  };

  const addAccordionClass = (idx) => {
    if (showAccordion.accordion) {
      return showAccordion.accordion[idx]
    }

    return false;
  };

  const getInnerHeight = (idx) => {
    if (showAccordion.height) {
      return showAccordion.height[idx]
    }

    return 0;
  };

  const displayIntro = () => {
    return (
      <div className="intro">
        <h2>Let's get your car fixed and back on the road.</h2>
        {data.general.country_code === 'au' && (
          <p>
            Get the best priced quotes fast now.
          </p>
        )}
        {data.general.country_code === 'nz' && (
          <p>
            Find out more today.
          </p>
        )}
        {data.general.country_code === 'au' && (
          <Link to="/quote" className="btn">
            Get free quotes
          </Link>
        )}
        {data.general.country_code === 'nz' && (
          <Link to="/demo" className="btn">
            Request demo
          </Link>
        )}
      </div>
    )
  };

  return (
    <div className="site-index-wrapper">
      <div className="container">
        {displayIntro()}
        <div className="site-index-accordion-wrapper">
          <div className={`accordion ${addAccordionClass(0) ? 'open' : 'closed'}`}>
            <div className="accordion-title"
              onClick={(e) => {
                e.preventDefault();
                toggleAccordion(0, e);
              }}>
              Common Services
              <div
                className="cross"
              />
            </div>
            <div className="accordion-body" style={{ height: getInnerHeight(0) }}>
              <div className="accordion-body-inner">
                <ul>
                  {services.length > 0 && (
                    services.map(({ node }) => (
                      <li key={node.id}>
                        <a href={node.url}>
                          {node.text}
                        </a>
                      </li>
                    )))}
                </ul>
              </div>
            </div>
          </div>
          <div className={`accordion ${addAccordionClass(1) ? 'open' : 'closed'}`}>
            <div className="accordion-title"
              onClick={(e) => {
                e.preventDefault();
                toggleAccordion(1, e);
              }}>
              Locations
              <div
                className="cross"
              />
            </div>
            <div className="accordion-body" style={{ height: getInnerHeight(1) }}>
              <div className="accordion-body-inner">
                {Object.keys(states).map((state) => {
                  const stateObj = states[state];
                  return (
                    <div key={stateObj.data.text}>
                      <h4>{stateObj.data.text}</h4>
                      <ul>
                        {stateObj.suburbs.map(({ node }) => (
                          <li key={node.url}>
                            <a href={node.url}>
                              {node.text}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={`accordion ${addAccordionClass(2) ? 'open' : 'closed'}`}>
            <div className="accordion-title"
              onClick={(e) => {
                e.preventDefault();
                toggleAccordion(2, e);
              }}>
              Car Makes
              <div
                className="cross"
              />
            </div>
            <div className="accordion-body" style={{ height: getInnerHeight(2) }}>
              <div className="accordion-body-inner">
                <ul>
                  {carMakes.length > 0 && (
                    carMakes.map(({ node }) => (
                      <li key={node.id}>
                        <a href={node.url}>
                          {node.text}
                        </a>
                      </li>
                    )))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteIndex;
