export const calculateRating = (count) => (
  ((100 * parseFloat(count)) / 5)
);

export const roundNumber = (number, decimalPlace) => (
  (Math.round(number * 10) / 10).toFixed(decimalPlace)
);

export const numberWithCommas = (x) => (
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
);

export const organiseLocations = (suburbList, stateList) => {
  const statesArr = [];
  stateList.forEach(({ node }) => {
    const state = node.text;
    statesArr[node.text] = {
      'data': node,
      'suburbs': suburbList.filter(({ node }) => node.state === state),
    };
  });

  return statesArr;
};